.form {
  padding: 8px 24px;
}

.works-form__preview-item {
  margin-bottom: 16px;
}

.works-form__preview-label {
  margin-bottom: 8px;
}
