.layout {
  max-width: 1700px;
  margin-top: 32px;
}

.logo {
  background: rgba(255, 255, 255, 0.2);
  margin: 32px 0;
  text-align: center;
}

.site-layout .site-layout-background {
  background: transparent;
}

[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}
